
import EmbudoService from "../../Services/EmbudoService";
import OportunidadService from "../../Services/OportunidadService";
import ModalCerrarOportunidad from "../oportunidades/componentes/ModalCerrarOportunidad.vue";
export default {
  name: "embudo-ventas-gestor",
  components: {
    CardEmbudoVentaHeader: () =>
      import("./componentes/CardEmbudoVentaHeader.vue"),
    CardEmbudoVenta: () => import("./componentes/CardEmbudoVenta.vue"),
    ModalCerrarOportunidad,
  },
  data() {
    return {
      breadcrumbs: [{ etiqueta: "Embudo de ventas" }],
      filtros: {
        busqueda: "",
        canalesCaptacionIds: [],
        ordenar: "registro_fecha_desc",
      },
      usuario: localStorage.getItem("nombreCorto"),
      embudoOpciones: [],
      embudoVentas: [],
      canalCaptacionOpciones: [],
      modalEditarOportunidad: false,
      editarOportunidadObj: {},
      modalCerrarOportunidad: false,
      embudoOportunidadCerradaId: "",
    };
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      let queryParams = this.$route.query;
      this.cargarFiltrosUrl(queryParams);
      await this.obtenerConcentradoOpciones(false);
      await this.obtenerEmbudoVentas(false);
      this.$nextTick(() => this.$refs.buscar.$el.focus());
      this.loader(false);
    },
    cargarFiltrosUrl(query) {
      this.filtros = {
        busqueda: query.b || "",
        canalesCaptacionIds: this.obtenerArrayParametrosQuery(query.cc, true),
        ordenar: "registro_fecha_desc",
      };
    },
    replaceUrl() {
      let filtros = {
        b: this.filtros.busqueda,
        cc: this.filtros.canalesCaptacionIds,
      };
      this.$router.replace({ query: filtros }).catch(() => {});
    },
    async obtenerEmbudoVentas(loader = true) {
      if (loader) this.loader(true);
      this.replaceUrl();
      await EmbudoService.listarEmbudoVentas(this.filtros)
        .then((resp) => {
          this.embudoVentas = resp;
          this.embudoOpciones = this.embudoVentas.map((e) => {
            return { embudo_id: e.embudo_id, titulo: e.titulo, color: e.color };
          });
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerConcentradoOpciones(loader = true) {
      if (loader) this.loader(true);
      await EmbudoService.obtenerConcentradoOpciones()
        .then((resp) => {
          this.canalCaptacionOpciones = resp.canalesCaptacion;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async manejarActualizacionStatus() {
      let statusObj = this.embudoOpciones.find(
        (s) => s.embudo_id == this.editarOportunidadObj.embudo_id
      );
      // Se valida tipo de embudo cerrado
      if (statusObj.color == this.constantesStatusCadena.embudoVerde500) {
        this.embudoOportunidadCerradaId = statusObj.embudo_id;
        this.modalCerrarOportunidad = true;
        return;
      }
      // Se actualiza embudo
      this.actualizarStatusEmbudo();
    },
    async actualizarStatusEmbudo() {
      this.loader(true);
      let datos = {
        oportunidadId: this.editarOportunidadObj.oportunidad_id,
        embudoId: this.editarOportunidadObj.embudo_id,
      };
      await OportunidadService.actualizarEmbudo(datos)
        .then(async (resp) => {
          this.cerrarModalActualizarStatus();
          await this.obtenerEmbudoVentas(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        canalesCaptacionIds: [],
        ordenar: "registro_fecha_desc",
      };
      this.obtenerEmbudoVentas();
      this.$refs.buscar.$el.focus();
    },
    abrirModalActualizarStatus(oportunidadObj) {
      if (
        oportunidadObj.status !=
        this.constantesStatusCadena.oportunidadEnProceso
      )
        return;

      if (!this.permisos.existePermiso(this.permisos.EMBUDO_STATUS_EDITAR)) {
        this.mostrarAlerta(
          `No cuenta con los permisos necesarios para realizar esta acción (${this.permisos.EMBUDO_STATUS_EDITAR})`,
          "warning"
        );
        return;
      }
      this.modalEditarOportunidad = true;
      this.editarOportunidadObj = _.cloneDeep(oportunidadObj);
    },
    cerrarModalActualizarStatus() {
      this.modalEditarOportunidad = false;
      this.editarOportunidadObj = {};
    },
    cerrarModalCerrarOportunidad() {
      this.embudoOportunidadCerradaId = "";
      this.modalCerrarOportunidad = false;
    },
    async manejarExitoCerrarOportunidad(mensaje) {
      this.cerrarModalCerrarOportunidad();
      this.cerrarModalActualizarStatus();
      await this.obtenerEmbudoVentas();
      this.mostrarAlerta(mensaje, "exito");
    },
  },
};
