
import OportunidadService from "@/Services/OportunidadService";
export default {
  name: "cerrar-oportunidad-modal",
  props: {
    modelo: {
      type: Boolean,
      required: true,
    },
    oportunidadObj: { type: Object, required: true },
    embudoId: { type: [Number, String], required: true },
  },
  data() {
    return { valorVenta: 0 };
  },
  watch: {
    modelo(val) {
      if (val) this.valorVenta = this.oportunidadObj.valor_venta;
    },
  },
  methods: {
    cerrarModal() {
      this.valorVenta = 0;
      this.$emit("cerrar-modal");
    },
    async cerrarOportunidad() {
      this.loader(true);
      let datos = {
        oportunidadId: this.oportunidadObj.oportunidad_id,
        status: this.constantesStatusCadena.oportunidadCerrada,
        embudoId: this.embudoId,
        valorVenta: this.valorVenta,
      };
      await OportunidadService.actualizarStatus(datos)
        .then(async (resp) => {
          this.loader(false);
          this.$emit("exito", resp.mensaje);
        })
        .catch((error) => {
          this.mostrarAlerta(error, "error");
          this.loader(false);
        });
    },
  },
};
