import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  listarGestor: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "oportunidades", "listarGestor"),
  listarDetalle: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "oportunidades", "listarDetalle"),
  obtenerDetalle: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "oportunidades", "obtenerDetalle"),
  obtenerConcentradoOpciones: (filtros) =>
    RepoUtils.ejecutarPeticionGet(
      filtros,
      "oportunidades",
      "obtenerConcentradoOpciones"
    ),

  // POST
  crear: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "oportunidades", "crear"),
  editarDescripcion: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "oportunidades", "editarDescripcion"),
  subirArchivo: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "oportunidades", "subirArchivo"),
  enviarEmail: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "oportunidades", "enviarEmail"),
  actualizarEmbudo: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "oportunidades", "actualizarEmbudo"),
  actualizarStatus: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "oportunidades", "actualizarStatus"),
  descargarArchivo: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "oportunidades", "descargar_archivo"),
};
