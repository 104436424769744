import axiosUtils from "./AxiosUtils";
import axiosConfig from "./AxiosConfig";
import axios from "axios";

export default {
  async ejecutarPeticionGet(filtros, modulo, Endpoint) {
    let params = { params: axiosUtils.getParams(filtros) };
    let respuesta = await axiosConfig.get(`${modulo}/${Endpoint}`, params);
    let data = respuesta.data;
    if (data.codigo !== 200) throw data.mensaje;
    return data.datos;
  },
  async ejecutarPeticionPost(datos, modulo, Endpoint) {
    let params = axiosUtils.getPostParams(datos);
    let respuesta = await axiosConfig.post(`${modulo}/${Endpoint}`, params);
    let data = respuesta.data;
    if (data.codigo !== 200) throw data.mensaje;
    return data;
  },
  // Peticion generada a apis externas
  async ejecutarPeticionGetExterna(filtros, apiUrl, modulo, Endpoint) {
    let params = { params: filtros };
    const http = axios.create({
      baseURL: apiUrl,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "json",
    });
    let respuesta = await http.get(`${modulo}/${Endpoint}`, params);
    return respuesta;
  },

  // Repos para peticiones con nuevo formato
  async ejecutarPeticionGetV2(filtros, modulo, id = "", endPoint = "") {
    let params = { params: axiosUtils.getParams(filtros) };
    if (id) modulo += `/${id}`;
    if (endPoint) modulo += `/${endPoint}`;
    let respuesta = await axiosConfig.get(modulo, params);
    let data = respuesta.data;
    if (data.codigo !== 200) throw data.mensaje;
    return data.datos;
  },
  async ejecutarPeticionPostV2(datos, modulo, id = "", endPoint = "") {
    let params = axiosUtils.getPostParams(datos);
    if (id) modulo += `/${id}`;
    if (endPoint) modulo += `/${endPoint}`;
    let respuesta = await axiosConfig.post(modulo, params);
    let data = respuesta.data;
    if (data.codigo !== 200) throw data.mensaje;
    return data;
  },
};
