import RepoUtils from "./Request/RepoUtils";

export default {
  // EMBUDO VENTAS
  listarEmbudoVentas: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "embudos", "listarEmbudoVentas"),
  obtenerConcentradoOpciones: (filtros) =>
    RepoUtils.ejecutarPeticionGet(
      filtros,
      "embudos",
      "obtenerConcentradoOpciones"
    ),

  // CONFIGURACION
  // GET
  listarGestor: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "embudos", "listarGestor"),
  listadoBasico: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "embudos", "listadoBasico"),
  // POST
  editarColor: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "embudos", "editarColor"),
  editarTitulo: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "embudos", "editarTitulo"),
  eliminar: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "embudos", "eliminar"),
  agregar: (data) => RepoUtils.ejecutarPeticionPost(data, "embudos", "agregar"),
};
