import { render, staticRenderFns } from "./EmbudoVentaGestor.vue?vue&type=template&id=4356c98b&"
import script from "./EmbudoVentaGestor.vue?vue&type=script&lang=js&"
export * from "./EmbudoVentaGestor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPage,QInput,QIcon,QSelect,QItem,QItemSection,QCheckbox,QScrollArea,QForm} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPage,QInput,QIcon,QSelect,QItem,QItemSection,QCheckbox,QScrollArea,QForm})
